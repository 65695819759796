import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Legal } from '../layouts/Legal';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Legal title="Service Level Agreement">{children}</Legal>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Effective date`}</strong>{`: 11 October 2021`}</p>
    <p><strong parentName="p">{`Schedule to Purchase Authorisation Form`}</strong></p>
    <p><strong parentName="p">{`Supplier Support Services Service Level Agreement (SLA)`}</strong></p>
    <p><strong parentName="p">{`Five Nines Digital Ltd. (Supplier) Customer Support Services`}</strong></p>
    <p>{`The help desk (`}<a parentName="p" {...{
        "href": "mailto:support@try.be"
      }}>{`support@try.be`}</a>{`) shall be used by the Customer to submit issues and by Supplier to provide technical support and answer specific information requests.`}</p>
    <p><strong parentName="p">{`Hours of Availability`}</strong></p>
    <p>{`Customer shall have telephone and email access to Supplier's Support Services help desk Monday to Saturday (Saturday over email) between 09:00 and 17:30 (GMT) ("Working Hours"), excluding public holidays in the UK. Extended support is provided for Service Outages, Critical and Major cases.`}</p>
    <p><strong parentName="p">{`Telephone:`}</strong>{` 020 4538 5555`}</p>
    <p><strong parentName="p">{`Email:`}</strong>{` `}<a parentName="p" {...{
        "href": "mailto:support@try.be"
      }}>{`support@try.be`}</a></p>
    <p><strong parentName="p">{`Security Level and Response Times`}</strong></p>
    <p>{`Acknowledgement and response times are determined based on the security level for any given error. Severity levels will be determined by the parties in accordance with the definitions below:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Severity`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Time for case acknowledgement`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Target resolution time`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Critical`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An Error which is causing the Software (in its entirety) to become inoperative or severely impaired and which has a severe commercial impact on Licensee business and there is no alternative processing, fix or workaround. Severely impaired system performance is in the event that one of the client's websites booking module is down and users cannot make a booking or perform a purchase, or the cient cannot log into the system at all, to manage bookings.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`1 hour`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Within 2 hours`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Major`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An Error which causes the loss of a major function or corrupts the client's website. One of the system functions supporting the business critical processes has failed, causing Licensee business to be significantly affected.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`2 hours`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Within 6 hours`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Minor`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Malfunctions causing the loss of a few minor functions within the Software having minor commercial impact on Licensee business. The Software is operative with some functions being limited, or non time critical functions being inoperative or a Major Error with an easy workaround solution.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`8 hours`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`72 hours`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Low`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The Software is operative, the Error causing a slight inconvenience, having no commercial impact on Licensee business.`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`8 hours`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Next or a later major release`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Procedure for Error Correction`}</strong></p>
    <ol>
      <li parentName="ol">{`Customer will endeavour to determine the source of the Error condition and make all reasonable efforts to recreate the Error and take relevant screen shots and information including the working journey to error, type of equipment (laptop, tablet etc.), browser used and any other technical information.`}</li>
      <li parentName="ol">{`In the event that the Software is the source for said Error condition, Customer Point of Contact will immediately report to Supplier with all the detailed information obtained with respect to such Error, in order to enable Supplier to correct such Error.`}</li>
      <li parentName="ol">{`Supplier will respond to the initial Error Report received from Customer with an acknowledgement of the Error, within the time periods specified in the table under Time for Case Acknowledgement.`}</li>
      <li parentName="ol">{`Supplier will start working in order to: identify an initial work-around, temporary fix, or correct the Error, within the timeframes specified in the table above, or in the event the Error reported is Low, notify Customer that the error reported is a known Error and will be resolved in a future release of the Software.`}</li>
    </ol>
    <p>{`Upon conclusion of the process for Error corrections set out above, an Error is classified as resolved by Supplier unless the appropriate Customer Point of Contact informs and presents to Supplier that the Error correction has not resolved the Error.`}</p>
    <p><strong parentName="p">{`Critical or Major Errors`}</strong></p>
    <p>{`In the event of a Critical or Major error Supplier will appoint our Incident Response Team to investigate and compile an incident report that will be shared with the Customer along with the measures that will be taken to ensure there is no repeat incident.`}</p>
    <p><strong parentName="p">{`SaaS Parameters`}</strong></p>
    <p>{`The following service parameters are Our responsibility in the ongoing support the SaaS:`}</p>
    <p><strong parentName="p">{`Availability`}</strong></p>
    <p>{`We will use commercially reasonable efforts to make the SaaS available 24 hours a day, seven days a week, except for unavailability during emergency or routine maintenance with an an Uptime Commitment of 99.9%. If we fall short of our 99.9% Uptime Commitment, the Customer will be eligible for Service Credit. If the Customer fails to report a Service Outage within five (5) days of the occurrence of such Service Outage, Customer shall not be entitled to any Service Credit for such Service Outage.`}</p>
    <p>{`You acknowledge and accept that Your access to the internet cannot be guaranteed and that We are not liable for deficiencies in Your own internet connections or equipment and that We are entitled to take measures that affect accessibility to the SaaS where We consider it to be necessary for technical, maintenance, operational or security reasons.`}</p>
    <p>{`You are responsible for ensuring that Your internet connections, computer unit and telephone solutions are compatible with the SaaS and for any damage that may be caused to such items by anything You access or obtain using them. We shall not be liable for any losses suffered by You as a result of any such incompatibility or damage. You are responsible for paying any and all charges in relation to Your internet connection, computer unit and telephone service.`}</p>
    <p><strong parentName="p">{`Maintenance Windows`}</strong></p>
    <p>{`We reserve the right to take the SaaS offline in order to carry out emergency maintenance but will endeavour to give Customer as much notice as reasonably possible.`}</p>
    <p><strong parentName="p">{`Software Upgrades`}</strong></p>
    <p>{`Updates and upgrades are performed at our discretion. You will be informed of all planned upgrades.`}</p>
    <p><strong parentName="p">{`Uptime commitment`}</strong></p>
    <p>{`Uptime is the percentage of total possible minutes Supplier was available during a fiscal quarter. Our commitment is to maintain at least 99.9% uptime:`}</p>
    <blockquote>
      <p parentName="blockquote">{`[(total minutes in quarter - downtime) / total minutes in quarter]`}{` > 99.9%`}</p>
    </blockquote>
    <p>{`To review current and history uptime, visit the `}<a parentName="p" {...{
        "href": "http://status.try.be"
      }}>{`Status Page`}</a></p>
    <p><strong parentName="p">{`Service Outage`}</strong></p>
    <p>{`Service Outage means any event that renders the platform unavailable to the Customer, other than Emergency Downtime or Excluded Downtime. A Service Outage is the overall number of minutes Supplier was unavailable during a Supplier fiscal quarter (i.e., 1 January to 31 March and every three-month period thereafter). Downtime must affect a significant number of requests or core functionality to qualify as a Service Outage. Supplier calculates unavailability using uptime monitoring software to measure the server-side error rate, ping test results and website tests.`}</p>
    <p><strong parentName="p">{`Excluded Downtime`}</strong></p>
    <p>{`A Service Outage caused by factors outside of Supplier's reasonable control does not factor into the Uptime Commitment calculation, including any force majeure event, Internet services availability outside of Supplier's platform, any downtime resulting from outages of external apps or third parties, and actions or inactions of the Customer (“Excluded Downtime”). Downtime also excludes any products or features identified as pilot, alpha, beta or similar and slowness or other performance issues with individual features and Emergency Downtime for maintenance.`}</p>
    <p><strong parentName="p">{`Service Credit`}</strong></p>
    <p>{`If we fall short of our Uptime Commitment, the Customer will be eligible to receive a Service Credit, as outlined in the below table. Service credit is not a refund, cannot be exchanged into a cash amount, is capped at a maximum of 30 days of paid service, requires the Customer to have paid any outstanding invoices and expires upon termination of the Customer's contract. To receive a Service Credit, you must submit a claim by emailing `}<a parentName="p" {...{
        "href": "mailto:support@try.be"
      }}>{`support@try.be`}</a>{` with dates and times of each unavailability incident that the Customer is claiming.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Uptime`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Service Credit`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`99.9% or above`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`None`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Between 99.0% and 99.9%`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`25% of monthly service charges`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`At or below 99.0%`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`50% of monthly service charges`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`SLA Updates`}</strong></p>
    <p>{`As our business evolves, we may change our service level agreement. Customers can review the most current version of the service level agreement at any time by visiting this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      